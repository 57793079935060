import React from "react";
import SEO from "../../components/common/seo.js";
import Layout from "../../components/common/layout.js";
import Navigation from "../../components/navigation/navigation.js";
import Header from "../../components/sections/header.js";
import Map from "../../components/sections/map.js";
import Contact from "../../components/sections/contact.js";
import { Dot, Container } from "../../components/global.js";
import styled from "styled-components";

const FundacjeRodzinne = () => (
  <Layout>
    <SEO
      title='Fundacje rodzinne'
      description='Kancelaria świadczy kompleksową pomoc prawną, związaną z zakładaniem i prowadzeniem nowej w polskim prawie instytucji – fundacji rodzinnej, w tym także pod kątem doradztwa podatkowego. Oferujemy fachowe usługi'
    />
    <Navigation />
    <Header />
    <Article id='page'>
      <Title>Fundacje rodzinne</Title>

      <Container>
        <p>
        Kancelaria świadczy kompleksową pomoc prawną, związaną z zakładaniem i prowadzeniem nowej w polskim prawie instytucji – fundacji rodzinnej, w tym także pod kątem doradztwa podatkowego. Oferujemy fachowe usługi m.in. w zakresie:
        </p>
        <List>
          <Dot>
          analizy możliwości oraz korzyści i ryzyk wynikających z założenia fundacji rodzinnej;
          </Dot>
          <Dot>sporządzenia statutu dostosowanego do indywidualnych potrzeb fundatora i jego oczekiwań;</Dot>
          <Dot>przeprowadzenia procesu zakładania i rejestracji fundacji rodzinnej;</Dot>
          <Dot>
          obsługi prawnej fundacji rodzinnej, obejmującej także prowadzenie przez fundację działalności gospodarczej, przewidzianej ustawą;
          </Dot>
          <Dot>zapewnienia prawidłowego funkcjonowania fundacji rodzinnej i czuwania nad realizacją jej celów zgodnie z wolą fundatora, w tym poprzez oddelegowanie jednego z naszych prawników do rady nadzorczej fundacji rodzinnej.</Dot>          
        </List>
      </Container>
    </Article>

    {/* <Contact /> */}
  </Layout>
);

export default FundacjeRodzinne;

const Title = styled.h4`
  margin-top: 75px;
`;

const List = styled.ul`
  padding: 40px 0px 150px;
  li {
    list-style-position: inside;
    text-indent: -1.5em;
    padding-left: 1.5em;
    line-height: 1.5;
  }
`;
const Article = styled.article`
  padding-top: 20px;
  /* border: 1px solid red; */
`;
